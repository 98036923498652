import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';
import { baseUri, hideLoader, showLoader } from '../../utils/utils';

import Auth, { Group } from '../../components/Auth';
import SEO from '../../components/SEO';
import axious from 'axios';
import { GlobalDispatchData } from '../../storage/GlobalDataProvider';
import { GlobalStateData } from '../../storage/GlobalDataProvider';

export default function Login() {
  const [identifier, setIdentifier] = useState();
  const [password, setPassword] = useState();
  const dispatch = useContext(GlobalDispatchData);
  const state = useContext(GlobalStateData);
  useEffect(() => {
    hideLoader();
    if (state.authData) {
      navigate('/');
    }
  }, []);

  const onCheckbox = () => {};

  const login = () => {
    showLoader();
    if (!identifier || !password) {
      let user = document.getElementById('identifier').value;
      let pass = document.getElementById('password').value;
      setIdentifier(user);
      setPassword(pass);
    }

    const postData = {
      identifier,
      password,
    };
    axious
      .post(baseUri() + 'auth/local/', postData)
      .then((result) => {
        document.getElementsByClassName('login-error')[0].classList.add('hide');
        dispatch({ type: 'APP_LOGIN', payload: result.data });
        navigate('/dashboard');
      })
      .catch((err) => {
        document.getElementsByClassName('login-error')[0].classList.remove('hide');
        hideLoader();
        //this.setState({ isError: true })
      });
  };

  return (
    <Auth title="Login" subTitle="Hello! Login with your username">
      <SEO title="Login" />
      <form>
        <InputGroup fullWidth>
          <input id="identifier" type="text" onChange={(e) => setIdentifier(e.target.value)} placeholder="Username" />
        </InputGroup>
        <InputGroup fullWidth>
          <input id="password" type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
        </InputGroup>
        {/* <Group>
          <Checkbox>Remember me</Checkbox>
          <Link to="/auth/request-password">Forgot Password?</Link>
        </Group> */}
        <p className="hide error-color login-error">Username or Password is Invalid</p>
        <Button status="Success" type="button" shape="SemiRound" fullWidth onClick={login}>
          Login
        </Button>
      </form>
      <Group>
        <Link to="/auth/request-password">Forgot Password?</Link>
      </Group>
    </Auth>
  );
}
